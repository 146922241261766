'use es6';

import { useLocalOverride } from './localVersionOverride';
import SprocketMenu from './SprocketMenu';
import PostFilterWidget from './PostFilterWidget';

var loadHubspotToolsMenu = function loadHubspotToolsMenu() {
  var menu = new SprocketMenu(window.hsVars);
  menu.showToolsMenuIfAuthor();
  var postFilterWidget = new PostFilterWidget();
  postFilterWidget.setup();
};

if (!useLocalOverride()) {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', function init() {
      loadHubspotToolsMenu();
      document.removeEventListener('DOMContentLoaded', init);
    }, false);
  } else {
    loadHubspotToolsMenu();
  }
}

export default SprocketMenu;